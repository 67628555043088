import React, { Component } from "react";
import "./stylesheets/style.css";
import logo from "./assets/LO-GO.svg";
import goTennis from "./assets/Gotennis3.svg";
import goTennisPrime from "./assets/Gotennis.svg";
import playStore from "./assets/Frame-3.png";
import emailSvg from "./assets/icons/email.svg";
import emailContact from "./assets/icons/emailContact.svg";
import statesSvg from "./assets/icons/states.svg";
import phoneSvg from "./assets/icons/phone.svg";
import notificationSvg from "./assets/icons/bell.svg";
import ringSvg from "./assets/icons/ring.svg";
import cloudSvg from "./assets/icons/cloud.svg";
import courtsSvg from "./assets/icons/courts.svg";
import palsSvg from "./assets/icons/Pals.svg";
import appleIcon from "./assets/Apple.svg";
import playIcon from "./assets/Playstore.svg";
import addressSvg from "./assets/icons/address.svg";
import whiteLogo from "./assets/tennisWhite.png";
import { Carousel } from "react-bootstrap";
import rectangleSvg from "./assets/cloud.png";
import "bootstrap/dist/css/bootstrap.min.css";
import Apple from "./assets/apple.svg";
import $ from "jquery";
import AOS from "aos";
import "aos/dist/aos.css";
import APIGateway from "./constants/ApiCall";
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      subject: "",
      message: "",
      alert: false,
      failure: false,
      dashboardDetail: {},
    };
  }
  componentDidMount() {
    AOS.init({
      disable: function () {
        var maxWidth = 1024;
        return window.innerWidth < maxWidth;
        // return true;
      },
    });
    var scrollComponent = this;
    document.addEventListener("scroll", function (e) {
      scrollComponent.toggleVisibility();
    });
    this.getTennisDetail();
  }

  toggleVisibility() {
    if (window.pageYOffset > 300) {
      this.setState({
        is_visible: true,
      });
    } else {
      this.setState({
        is_visible: false,
      });
    }
  }

  getTennisDetail = () => {
    APIGateway.get("user/auth/dashboard", (response) => {
      if (response.success) {
        this.setState({
          dashboardDetail: response.data,
        });
      }
    });
  };
  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  handleSubmit = (event) => {
    const { name, email, subject, message } = this.state;
    event.preventDefault();
    $.ajax({
      url: "https://formsubmit.co/ajax/gotennisgt@gmail.com",
      type: "POST",
      data: {
        name: name,
        email: email,
        subject: subject,
        message: message,
      },
      cache: false,
      success: function (data) {
        this.setState(
          {
            email: "",
            message: "",
            name: "",
            subject: "",
            alert: true,
          },
          () => setTimeout(() => this.setState({ alert: false }), 3000)
        );
      }.bind(this),
      error: function (xhr, status, err) {
        this.setState({
          failure: true,
        });
      }.bind(this),
    });
  };
  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleNavigate = (key) => {
    let gLink = "https://play.google.com/store/apps/details?id=com.gotennis";
    let appLink = "https://apps.apple.com/in/app/tennis-go/id1558064540";
    if (key === "google") {
      window.open(gLink, "_blank");
    } else if (key === "apple") {
      window.open(appLink, "_blank");
    }
  };
  render() {
    const {
      nextIcon,
      prevIcon,
      email,
      name,
      subject,
      message,
      dashboardDetail,
      is_visible,
      alert,
      failure,
    } = this.state;

    return (
      <>
        <div className="App">
          <div className="app_inherit">
            <img src={logo} onClick={() => this.scrollToTop()}></img>
            <ul>
              <li>
                {" "}
                <a href="#features">Features</a>
              </li>
              <li>
                <a href="#statistics">Statistics</a>
              </li>
              <li>
                <a href="#contact">Contact</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="img-slide-res" style={{ paddingTop: "48px" }}>
          <Carousel interval={5000} prevIcon={null} nextIcon={null} fade={true}>
            <Carousel.Item>
              <div className="App__sliderImg">
                <p data-aos="fade-up" data-aos-duration="1300">
                  We’re Done Waiting <br></br> for Court Time
                </p>
                <span
                  data-aos="fade-down"
                  data-aos-duration="1300"
                  className="tennis-transparent"
                >
                  tennis
                </span>
                <p
                  className="content-slide"
                  data-aos="fade-up"
                  data-aos-duration="1300"
                >
                  See live updates when public and community <br />
                  courts are available.
                </p>
                <div className="play-link-extend">
                  <div
                    className="apple_store"
                    onClick={() => this.handleNavigate("google")}
                  >
                    <img src={playIcon} />
                    <p className="app-cont">Google Play</p>
                  </div>
                  <div
                    className="apple_store"
                    onClick={() => this.handleNavigate("apple")}
                  >
                    <img src={appleIcon} />
                    <p className="app-cont">App Store</p>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="App__sliderImg2">
                {" "}
                <p>
                  The Weather Shouldn’t <br></br>Stand in Your Way{" "}
                </p>
                <p className="content-slide">
                  Use our weather tracking features to make sure <br />
                  you get on court and stay on court!
                </p>
                <div className="play-link-extend">
                  <div
                    className="apple_store"
                    onClick={() => this.handleNavigate("google")}
                  >
                    <img src={playIcon} />
                    <p className="app-cont">Google Play</p>
                  </div>

                  <div
                    className="apple_store"
                    onClick={() => this.handleNavigate("apple")}
                  >
                    <img src={appleIcon} />
                    <p className="app-cont">App Store</p>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="App__sliderImg3">
                {" "}
                <p>
                  Play Tennis <br></br>Uninterrupted
                </p>
                <span className="tennis-transparent">Play</span>
                <p className="content-slide">
                  Use our chat feature to keep up to date <br />
                  with your tennis partners.
                </p>
                <div className="play-link-extend">
                  <div
                    className="apple_store"
                    onClick={() => this.handleNavigate("google")}
                  >
                    <img src={playIcon} />
                    <p className="app-cont">Google Play</p>
                  </div>

                  <div
                    className="apple_store"
                    onClick={() => this.handleNavigate("apple")}
                  >
                    <img src={appleIcon} />
                    <p className="app-cont">App Store</p>
                  </div>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
        <div className="App__whyGo">
          <h2 data-aos="fade-up" data-aos-duration="1300">
            Why GoTennis?
          </h2>
          <p data-aos="fade-up" data-aos-duration="1300">
            We understand how difficult it can be know availability of a public
            court in your hometown, and it <br></br> is awful when you pull up
            to the courts and they are packed, which is why we developed Go!
            Tennis<br></br>This app offers you tennis court availability at your
            fingertips!{" "}
          </p>
        </div>
        <div className="App__features" id="features">
          <div className="img-feature">
            <img
              src={goTennis}
              data-aos="fade-up"
              data-aos-duration="1300"
            ></img>
          </div>
          <div className="text-feature">
            <div>
              <h4 data-aos="fade-up" data-aos-duration="1300">
                MEET WITH OUR
              </h4>
              <h1 data-aos="fade-up" data-aos-duration="1300">
                Splendid Features
              </h1>
            </div>
            <div>
              {" "}
              <div
                className="content-icon-flex"
                data-aos="fade-up"
                data-aos-duration="1300"
              >
                {" "}
                <div className="feature-imge">
                  <img src={emailSvg} className="feature-icons" />
                </div>
                <div className="points-text">
                  {/* <div> */}
                  {/* <div>
                      <img src={emailSvg} className="feature-icons" /> */}
                  <p> Chat - Book - Play</p>
                  {/* </div> */}
                  <p>
                    You will also be able to book your favorite court and
                    collaborate with your tennis pals through the app’s chat
                    feature.{" "}
                  </p>
                  {/* </div> */}
                </div>
              </div>
              <div
                className="content-icon-flex"
                data-aos="fade-up"
                data-aos-duration="1300"
              >
                <div className="feature-imge res-img">
                  <img src={notificationSvg} className="feature-icons" />
                </div>
                <div className="points-text">
                  <p>Notification Reminders</p>
                  <p>
                    When using this app to book your court times, it will send
                    you helpful notification reminders that will make sure you
                    are on time and that your friends know when to meet up.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="App__features_extended">
          <div className="text-feature secondary">
            <h4 data-aos="fade-up" data-aos-duration="1300" className="enjoy">
              ENJOY WITH OUR EXTENDED
            </h4>
            <h1 data-aos="fade-up" data-aos-duration="1300">
              Awesome Features
            </h1>
            <div>
              <div
                className="content-icon-flex"
                data-aos="fade-up"
                data-aos-duration="1300"
              >
                <div className="feature-imge">
                  <img src={ringSvg} className="feature-icons" />
                </div>

                <div className="points-text">
                  <p>Checkin and Block your slots</p>
                  <p>
                    With the check-in feature, the tennis court will become
                    unavailable to other app users so that you aren’t
                    interrupted mid-set.
                  </p>
                </div>
              </div>
              <div
                className="content-icon-flex"
                data-aos="fade-up"
                data-aos-duration="1300"
              >
                <div className="feature-imge res-img">
                  <img src={cloudSvg} className="feature-icons" />
                </div>
                <div className="points-text">
                  <p>Plan Ahead with our Weather Feature</p>
                  <p>
                    If the weather is messing up your plans, we’ve got your
                    back. Go! Tennis is the first tennis app that gives you
                    accurate minute-by-minute weather forecasts. If it’s raining
                    at your usual court, the app will offer suggestions of other
                    courts that are rain and wind-free.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="img-feature">
            <img
              src={goTennisPrime}
              data-aos="fade-up"
              data-aos-duration="1300"
            ></img>
          </div>
        </div>
        <div className="App__groww" id="statistics">
          <div className="map_png">
            <h3 data-aos="fade-up" data-aos-duration="1300">
              Growing Quickly
            </h3>
            <p data-aos="fade-up" data-aos-duration="1300">
              All courts are verified. Find your favorite tennis court or add
              one to our list. Go to MY PROFILE - Fill out a form - Submit and
              relax. <br /> Once verified, your court will be active on our
              list.
            </p>

            <section id="counts" className="App__counts">
              <div
                className="container"
                data-aos="fade-up"
                data-aos-duration="1300"
              >
                <div className="row gy-8">
                  <div className="col-lg-3 col-md-6">
                    <div className="count-box">
                      <img src={courtsSvg} className="statistic-img"></img>
                      <div style={{ marginLeft: "47px" }}>
                        <span
                          data-purecounter-start="0"
                          data-purecounter-end="521"
                          data-purecounter-duration="1"
                          class="purecounter"
                        >
                          {Math.floor(dashboardDetail.courts / 1000)}k
                        </span>
                        <p>COURTS</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6">
                    <div className="count-box">
                      <img src={statesSvg} className="states-img"></img>
                      <div style={{ marginLeft: "16px" }}>
                        <span
                          data-purecounter-start="0"
                          data-purecounter-end="1463"
                          data-purecounter-duration="1"
                          class="purecounter"
                        >
                          40+
                        </span>
                        <p>STATES</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-3 col-md-6">
                    <div class="count-box">
                      <img src={palsSvg} className="states"></img>
                      <div style={{ marginLeft: "16px" }}>
                        <span
                          data-purecounter-start="0"
                          data-purecounter-end="15"
                          data-purecounter-duration="1"
                          class="purecounter"
                        >
                          {dashboardDetail.users > 100 ? "100+" : "0"}
                        </span>
                        <p>TENNIS PALS</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>

        <div className="App__phone">
          <div className="download" data-aos="fade-up" data-aos-duration="1300">
            <h4>Download the App Now</h4>
            <p>
              The Go! Tennis app is available on the iPhone and Android. If you
              check in 10 times per month, the &cent;99/month fee will be
              waived. Download the app <b>NOW</b> .
            </p>
            <p>
              P.S. If you can’t find your favorite place to play on the app,
              simply notify us and we will verify the court and all of the
              details within 24 hours.
            </p>
            <div className="play-link">
              <a
                href="https://play.google.com/store/apps/details?id=com.gotennis"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                <img src={playStore} />
              </a>
              <a
                href="https://apps.apple.com/in/app/tennis-go/id1558064540"
                target="_blank"
                rel="noreferrer"
              >
                <img className="store" src={Apple} />
              </a>
            </div>
          </div>
          <div className="phone-imge">
            <img src={rectangleSvg} />
          </div>
        </div>

        <div
          className="App__testimonal"
          data-aos="fade-up"
          data-aos-duration="800"
        >
          <div className="testi-box">
            <p className="quote-icon">&#8221;</p>

            <Carousel interval={1300} nextIcon={nextIcon} prevIcon={prevIcon}>
              <Carousel.Item>
                <div className="content_testi">
                  <p className="content-text">
                    I couldn’t find my court on the existing list. However they
                    are letting me to add my court details. Which is cool. I
                    like that idea. Love this App so far.
                  </p>
                  <span>Alex Breyne</span>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="content_testi">
                  <p className="content-text">
                    Really great source of seasonal tennis players.This app is
                    great, keeps me make informed decision on which court I
                    should go. I am saving more waiting time. This is what we
                    needed.
                  </p>
                  <span>Raj Bhawan</span>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="content_testi">
                  <p className="content-text">
                    Excellent Mobile App that helps discovering available courts
                    near me and keep us motivated. Easy to enroll and use.
                  </p>
                  <span>Adam Williams</span>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="content_testi">
                  <p className="content-text">
                    My court is very busy. it is hard to get availability. Need
                    to wait for long time without knowing when other player will
                    complete. This App is really handy. Saving my precious time
                    getting wasted in waiting. Love this idea. Easy to use. Love
                    this so far!
                  </p>
                  <span>Johnny Pibre</span>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="content_testi">
                  <p className="content-text">
                    Perfect app for tennis player 😎😎.
                  </p>
                  <span>Emilie Bud</span>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
          <div className="testi-content">
            <p>Happy Customers!</p>
            <p>We give our best to make you happy!</p>
            <p>
              Customer satisfaction is the most important service we can provide
              to our community. Hear from some of our happy users.
            </p>
          </div>
        </div>
        <section id="contact" className="App__contact">
          <div className="containered">
            <header className="section-header">
              <h1 className="contact-tag">Contact Us</h1>
            </header>

            <div className="row gy-4">
              <div className="col-lg-6">
                <div className="row gy-4">
                  <div className="col-md-6">
                    <div className="info-box">
                      <a href="mailto: digital.convazant@gmail.com">
                        <img src={emailContact} />
                      </a>

                      <h3>Email Us</h3>
                      <p>
                        gotennisgt@gmail.com
                        <br />
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="info-box">
                      <a href="tel:+1(704)-804-5996">
                        <img src={phoneSvg} />
                      </a>
                      <h3>Call Us</h3>
                      <p>
                        +1(704)-804-5996
                        <br />
                      </p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="info-box">
                      <a
                        href="http://maps.google.com/?q=3230 Prosperity church rd,  Suite 202 Chatlotte, NC 28269 USA."
                        target="_blank"
                      >
                        <img className="img-address" src={addressSvg} />
                      </a>

                      <h3>Address</h3>
                      <p>
                        3230 Prosperity church rd,
                        <br />
                        Suite 202 Chatlotte, NC 28269 USA.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <form
                  onSubmit={this.handleSubmit}
                  id="formContact"
                  className="php-email-form"
                >
                  <div className="row gy-4">
                    <div className="col-md-6">
                      <input
                        type="text"
                        name="name"
                        value={name}
                        onChange={this.handleInputChange}
                        className="form-control"
                        placeholder="Your Name"
                        required
                      />
                    </div>

                    <div className="col-md-6 ">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        value={email}
                        onChange={this.handleInputChange}
                        placeholder="Your Email"
                        required
                      />
                    </div>

                    <div className="col-md-12">
                      <input
                        type="text"
                        className="form-control"
                        name="subject"
                        value={subject}
                        onChange={this.handleInputChange}
                        placeholder="Subject"
                        required
                      />
                    </div>

                    <div className="col-md-12">
                      <textarea
                        className="form-control"
                        name="message"
                        value={message}
                        onChange={this.handleInputChange}
                        rows="6"
                        placeholder="Message"
                        required
                      ></textarea>
                    </div>

                    <div className="col-md-12 text-center">
                      <div className="loading">Loading</div>
                      <div className="error-message"></div>

                      <button type="submit">Send Message</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="success-div">
            <span className={alert ? "success" : "none"}>
              Your message has been sent. Thank you!
            </span>
          </div>
          <div className="success-div">
            <span className={failure ? "failure" : "none"}>
              Sorry, there has been an error.Please try again later.
            </span>
          </div>
        </section>

        <div className="App__contacting">
          <div className="foot-text">
            <img src={whiteLogo} onClick={() => this.scrollToTop()} />
            <div className="go-foot">
              <p> Go Tennis © 2021. </p>
              <p style={{ marginLeft: "0.6rem" }}> A Product of Convazant.</p>
            </div>

            <p>All rights reserved.</p>
          </div>
        </div>

        <div className="scroll-to-top">
          {is_visible && (
            <div
              onClick={() => this.scrollToTop()}
              className="icon-box"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <i className="fa fa-angle-up icon-arrow"></i>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default App;
